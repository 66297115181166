import styled from "styled-components";
import { ComingSoon } from "../components/ComingSoon";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Account = () => {
  return <Container><ComingSoon /></Container>;
};

export { Account };