'use client'

import styled from "styled-components"
import signInBackground from '../../images/sign-in-background.png'

export const Container = styled.div`
display: flex;
`

export const SignInSection = styled.div`
min-height: 100vh;
width: 500px;

display: flex;
flex-direction: column;

align-items: center;

padding: 0 75px;
`

export const Welcome = styled.div`
display: flex;
flex-direction: column;

align-items: center;
margin-top: 150px;

gap: 10px;
`

export const WelcomeText = styled.p`
font-size: 30px;
`

export const CompanyNameText = styled.p`
font-size: 65px;
`


export const SignInControlsStyled = styled.div`
display: flex;
flex-direction: column;

align-items: center;

width: 100%;
margin: auto 0 auto 0;

gap: 20px;

`

export const SignInControlsText = styled.p`
font-weight: bold;
`

export const ImageSection = styled.div`
height: 100vh;
display: flex;
flex-grow: 1;
justify-content: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
background-image: url(${signInBackground})
`

export const ImageOverlay = styled.div`
background-color: rgba(0,0,0,0.6);
width: 100%;
height: 100%;
position: absolute;
`

export const ImageSectionTextContainer = styled.div`
z-index: 2;
padding: 60px;

display: flex;
flex-direction: column;
align-items: end;

margin: auto 0 100px 0;

gap: 30px;
`

export const ImageSectionText = styled.p`
color: white;
font-weight: 500;
font-size: 40px;
${ props => props.customstyle }
`