import {
  Container,
  TextsContainer,
  Title,
  Text,
  InputFieldsContainer,
  Row,
  InputField,
} from "./Components.js";

const AdminPage = ({ setActiveStep, admin, setAdmin }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveStep(2);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAdmin((admin) => ({ ...admin, [name]: value }));
  };

  return (
    <Container>
      <TextsContainer>
        <Title>Välkommen! Börja med företagsinformationen!</Title>
        <Text>Du kan alltid ändra det senare i inställningarna</Text>
      </TextsContainer>
      <InputFieldsContainer onSubmit={handleSubmit}>
        <Row>
          <InputField
            required
            type="text"
            placeholder="Omar"
            label="Förnamn"
            name="firstName"
            defaultValue={admin.firstName}
            onChange={handleChange}
          />
          <InputField
            required
            type="text"
            placeholder="Diriye"
            label="Efternamn"
            name="lastName"
            defaultValue={admin.lastName}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <InputField
            required
            type="text"
            placeholder="199001102384"
            label="Personnummer"
            name="corporate_form"
            defaultValue={admin.corporate_form}
            onChange={handleChange}
          />
          <InputField
            required
            type="text"
            placeholder="072 434 54 95"
            label="Telefonnummer"
            name="phone_number"
            defaultValue={admin.phone_number}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <InputField
            required
            type="text"
            label="Mejl"
            placeholder="exempel@mail.se"
            name="email"
            defaultValue={admin.email}
            onChange={handleChange}
          />
        </Row>
        <button type="submit">Gå Vidare</button>
      </InputFieldsContainer>
    </Container>
  );
};

export default AdminPage;