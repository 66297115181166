import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
} from "react-router-dom";
import { NotFound } from "../pages/NotFound/NotFound";
import Login from "../pages/Login/Login";
import { RootLayout } from "./RootLayout";
import { Clients } from "../pages/Clients";
import { Account } from "../pages/Account";
import { AddClient } from "../pages/AddClient/AddClient";
import { ProtectedRoute } from "./ProtectedRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Outlet />}>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <RootLayout indexPath="clients" />
          </ProtectedRoute>
        }
      >
        <Route path="clients" element={<Clients />} />
        <Route path="add-client" element={<AddClient />} />        
        <Route path="account" element={<Account />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export { router };