"use client";

import styled from "styled-components";
//import Image from "next/image";
import GoogleLogo from "../images/google.png";
//import { Inter } from 'next/font/google'


/*const inter = Inter({
    weight: "400",
    subsets: ["latin"],
  });*/

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 8px 16px;
  background: white;
  border: 2px solid #a6a6a6;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  ${ props => props.customstyle }
`;

const Label = styled.p`
  color: ${(props) => props.color || "white"};
`;

const LogoSize = 20;

export const Google = ({ label, ...props }) => {
  return (
    <Container {...props}>
      <img alt="google logo" src={GoogleLogo} width={LogoSize} height={LogoSize} />
      <Label color="#676767">{label}</Label>
    </Container>
  );
};