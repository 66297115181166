import { db } from "./init";
import { collection, query, where, getDocs } from "firebase/firestore";

export const getAllUsers = async () => {
  const q = query(collection(db, "users"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
  });
};

export const findUserWithEmail = async (email) => {
    var result = undefined;

    const q = query(collection(db, "control_panel_admins"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      var user = doc.data();

      if (user.email == email) {
        result = doc.data();
      }
    });

    return result;
}