import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p``;

const ComingSoon = () => {
  return (
    <Container>
      <Text>
        Den här sidan är under påbyggnad och kommer inom en snar framtid!
      </Text>
    </Container>
  );
};

export { ComingSoon };
