import {
  Container,
  TextsContainer,
  Title,
  Text,
  InputFieldsContainer,
  Row,
  InputField,
} from "./Components.js";

const CompanyPage = ({ setActiveStep, company, setCompany }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    setActiveStep(1)
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCompany((company) => ({ ...company, [name]: value }));
  };

  return (
    <Container>
      <TextsContainer>
        <Title>Välkommen! Börja med företagsinformationen!</Title>
        <Text>Du kan alltid ändra det senare i inställningarna</Text>
      </TextsContainer>
      <InputFieldsContainer onSubmit={handleSubmit}>
        <Row>
          <InputField
            required
            type="text"
            //pattern="[0-9]{10}"
            placeholder="1234567890"
            label="Organisationsnummer"
            name="organisation_number"
            defaultValue={company.organisation_number}
            onChange={handleChange}
          />
          <InputField
            required
            type="text"
            placeholder="Företagsnamn"
            label="Företagsnamn"
            name="name"
            defaultValue={company.name}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <InputField
            required
            type="tel"
            //pattern="[0-9]{10}"
            placeholder="0724345495"
            label="Telefonnummer"
            name="phone_number"
            defaultValue={company.phone_number}
            onChange={handleChange}
          />
          <InputField
            required
            type="email"
            placeholder="exempel@mail.se"
            label="Mejl"
            name="email"
            defaultValue={company.email}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <InputField
            required
            type="text"
            //pattern="[0-9]{5}"
            placeholder="48745"
            label="Postnummer"
            name="postal_code"
            defaultValue={company.postal_code}
            onChange={handleChange}
          />
          <InputField
            required
            placeholder="Essensivägen 23"
            label="Adress"
            name="address"
            defaultValue={company.address}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <InputField
            required
            placeholder="Göteborg"
            label="Postort"
            name="postal_address"
            defaultValue={company.postal_address}
            onChange={handleChange}
          />
        </Row>
        <button type="submit">Gå Vidare</button>
      </InputFieldsContainer>
    </Container>
  );
};

export default CompanyPage;