import styled from "styled-components";
import { useState, useEffect } from "react";
import ArrowLogo from "../images/arrow.png";
import DashboardActive from "../images/dashboard-active.png";
import DashboardInActive from "../images/dashboard-inactive.png";
import AccountActive from "../images/account-active.png";
import AccountInactive from "../images/account-inactive.png";
import ParticipantActive from "../images/participant-active.png";
import ParticipantInactive from "../images/participant-inactive.png";
import ChatActive from "../images/chat-active.png";
import ChatInactive from "../images/chat-inactive.png";
import CalendarActive from "../images/calendar-active.png";
import CalendarInactive from "../images/calendar-inactive.png";
import { Link, useLocation } from "react-router-dom";

const SideMenuContainer = styled.div`
  height: 100vh;
  min-width: 175px;
  max-width: 175px;
  background-color: #0f0f0f;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: min-width 0.8s;

  &.collapsed {
    min-width: 75px;
    max-width: 75px;  }
`;

const ArrowButton = styled.img`
  height: 11px;
  background-color: #0f0f0f;
  padding: 13px;
  border-radius: 50%;
  position: absolute;
  margin: 0 0 auto auto;
  top: 25px;
  right: -15px;
  cursor: pointer;
  transition: transform 0.7s;

  &.collapsed {
    transform: rotate(-180deg);
    right: -15px;
  }
`;

const InitialsContainer = styled.div`
height: 100px;
width: 100px;
background-color: #4A4A4A;
margin-top: 100px;
`
const InitialsText = styled.p``

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 22px;
  flex-grow: 1;

  a {
    text-decoration: none;
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  height: 20px;
  color: ${(props) => props.$active ? "#fff" : "#bababa"};
  cursor: pointer;
  transition: gap 0.5s;
  &:hover {
    opacity: 0.8;
  }

  &.collapsed {
      gap: 0px;
  }
`;

const MenuItemIcon = styled.img`
  height: 17px;
  left: 0;
  transition: height 0.5s;
  margin-bottom: 3px;

  &.collapsed {
    height: 21px;
  }
`;
const MenuItemText = styled.p`
  font-size: 20px;
  transition: width 0.7s, opacity 0.5s;
  width: 100px;
  height: 23px;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;

  &.collapsed {
    width: 0;
    opacity: 0;
  }
`;


var menuItems = [
  {
    title: "Kunder",
    link: "clients",
    activeIcon: ParticipantActive,
    inactiveIcon: ParticipantInactive,
  },
  {
    title: "Konto",
    link: "account",
    activeIcon: AccountActive,
    inactiveIcon: AccountInactive,
  },
];

function Sidebar({ user, onLogout }) {
  const [collapsed, setCollapsed] = useState(true);
  const [showMenuItems, setShowMenuItems] = useState(!collapsed);
  const [activePageIndex, setActivePageIndex] = useState(0);
  let location = useLocation();

  const getActivePageIndex = () => {
    var path = location.pathname.split("/")[1];
    if (path == "customers") return 0;
    if (path == "account") return 1;

    return 0;
  }

  useEffect(() => {
    setActivePageIndex(getActivePageIndex())
  }, [location])

  return (
    <SideMenuContainer className={collapsed ? "collapsed" : ""}>
      <ArrowButton
        src={ArrowLogo}
        onClick={(e) => {
          e.target.classList.toggle("collapsed");
          const delay = 300;

          setTimeout(() => {
            setCollapsed(!collapsed);
          }, delay);

          setTimeout(
            () => {
              setShowMenuItems(collapsed ? true : false);
            },
            collapsed ? delay : delay
          );
        }}
      />
      <MenuItemsContainer>
        {menuItems.map((item, index) => {
          const { title, link, activeIcon, inactiveIcon } = item;
          const isActive = index == activePageIndex;

          return (
            <Link to={link}
            key={title + link}
            >
              <MenuItem $active={isActive} className={showMenuItems ? "" : "collapsed"}>
                <MenuItemIcon
                  src={isActive ? activeIcon : inactiveIcon}
                  className={showMenuItems ? "" : "collapsed"}
                />
                {
                  <MenuItemText className={showMenuItems ? "" : "collapsed"}>
                    {title}
                  </MenuItemText>
                }
              </MenuItem>
            </Link>
          );
        })}
      </MenuItemsContainer>
      <button onClick={() => onLogout()}>Log out</button>
    </SideMenuContainer>
  );
}



export { Sidebar };