import styled from "styled-components";
import SearchLogo from "../images/search.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;
const SearchIcon = styled.img`
  position: absolute;
  left: 11px;
  height: 17px;
  cursor: pointer;
`;
const InputField = styled.input`
  border: none;
  padding: 10px 35px;
  border-radius: 10px;
  width: 250px;
  color: #737373;
  letter-spacing: 0.5px;

  :focus {
    outline: 1px solid rgba(0, 0, 0, 0.1);
  }

  ::placeholder {
    color: #a2a2a2;
  }
`;

const SearchField = () => {
  return (
    <Container>
      <SearchIcon src={SearchLogo} />
      <InputField placeholder="Sök" />
    </Container>
  );
};

export { SearchField };