import "./reset.css";
import { useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import { Loading } from "../pages/Loading";
import { router } from "./router";

function App() {
  const { user } = useContext(AuthContext);

  if (user === null) {
    return <Loading />;
  }

  return <RouterProvider router={router} />;
}

export default App;