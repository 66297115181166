// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import essensiConfig from "./essensi-config.json";
import essensiTestConfig from "./essensi-test-config.json";

// Initialize Firebase
const app = initializeApp(essensiTestConfig);
//const analytics = getAnalytics(app);

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { app, auth, db}