import { Link } from "react-router-dom";

import styled from "styled-components";

const Container = styled.div``;

const Title = styled.h1``;

const Subtitle = styled.h2``;

const Text = styled.p``;

const GoBackButton = styled.button``;

const NotFound = () => {
  return (
    <Container>
      <Title>404</Title>
      <Subtitle>Åh nej... Den här sidan finns inte</Subtitle>
      <Text>
        Vi har letat efter den här sidan men kunde inte hitta det som du letade
        efter. Låt oss hitta ett bättre ställe för dig att gå till.{" "}
      </Text>
      <Link to="/">
        <GoBackButton>Gå Tillbaka</GoBackButton>
      </Link>
    </Container>
  );
};

export { NotFound };
