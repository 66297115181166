import { useState } from "react";
import styled from "styled-components";
import { Stepper, Step } from "react-form-stepper";
import CompanyPage from "./CompanyPage";
import AdminPage from "./AdminPage";
import OfficesPage from "./OfficesPage";
import { useNavigate } from "react-router-dom";
import { addCompany } from '../../firebase/companies'
import { addAdmin } from '../../firebase/admins'
import { addOffices } from '../../firebase/offices'

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  min-height: 100%;
`;

const stepStyleDTO = {
  activeBgColor: "#53C945",
  activeTextColor: "#fff",
  completedBgColor: "#53C945",
  completedTextColor: "#fff",
};

const connectorStyleProps = {
  activeColor: "#53C945",
  completedColor: "#53C945",
};

const AddClient = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [company, setCompany] = useState({});
  const [admin, setAdmin] = useState({});
  const [offices, setOffices] = useState([""]);
  const navigate = useNavigate();

  const createAccount = async () => {
    const companyId = await addCompany(company);
    
    const newAdmin = {...admin, company_id: companyId}
    await addAdmin(newAdmin)

    var newOffices = offices.filter(address => address !== "")
    newOffices = newOffices.map((address) => {
      return { company_id: companyId, address: address}
    })
    await addOffices(newOffices);
    navigate("/clients")
  };

  return (
    <Container>
      <FormContainer>
        <Stepper
          style={{ width: "80%" }}
          activeStep={activeStep}
          styleConfig={stepStyleDTO}
          connectorStateColors={true}
          connectorStyleConfig={connectorStyleProps}
        >
          {[...Array(3)].map((_, index) => (
            <Step
              key={`stepper-${index}`}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </Stepper>
        {activeStep === 0 && (
          <CompanyPage
            setActiveStep={setActiveStep}
            company={company}
            setCompany={setCompany}
          />
        )}
        {activeStep === 1 && (
          <AdminPage
            setActiveStep={setActiveStep}
            admin={admin}
            setAdmin={setAdmin}
          />
        )}
        {activeStep === 2 && (
          <OfficesPage
            createAccount={createAccount}
            offices={offices}
            setOffices={setOffices}
          />
        )}
      </FormContainer>
    </Container>
  );
};

export { AddClient };
