import React from 'react'
import {
  Container,
  SignInSection,
  Welcome,
  WelcomeText,
  CompanyNameText,
  ImageSection,
  ImageOverlay,
  ImageSectionTextContainer,
  ImageSectionText,
  SignInControlsStyled,
  SignInControlsText
} from "./Login.styled";
import { Google } from "../../components/LogInButton";
import { AuthContext } from "../../contexts/auth";
import { Navigate } from "react-router-dom";

export default function Page() {
  const { user, failedLogin, onLogin } = React.useContext(AuthContext);

  if (user) {
    return <Navigate to="/" replace={true} />;
  }
  
  return (
    <Container>
      <SignInSection>
        <Welcome>
          <WelcomeText>Välkommen till</WelcomeText>
          <CompanyNameText>ESSENSI</CompanyNameText>
        </Welcome>
        <SignInControlsStyled>
          <SignInControlsText>Logga in med</SignInControlsText>
          <Google
            label="Google"
            onClick={() => onLogin()}
          />
          
          <p style={{visibility: !failedLogin ? "hidden" : "visible"}}>KONTO HITTAS EJ</p>
          
        </SignInControlsStyled>
      </SignInSection>
      <ImageSection>
        <ImageOverlay></ImageOverlay>
        <ImageSectionTextContainer>
          <ImageSectionText>
          &quot;Revolutionera ditt leverantörsbolag med vår smarta teknologi -
            Effektivisera ditt företag idag!&quot;
          </ImageSectionText>
          <ImageSectionText
            customstyle={"margin-right: 150px;"}
          >
            - Essensi
          </ImageSectionText>
        </ImageSectionTextContainer>
      </ImageSection>
    </Container>
  );
}