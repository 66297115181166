import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
`;

export const Text = styled.p`
  color: #808080;
  width: fit-content;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
`;

export const InputFieldsContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 5px;
`;

export const InputField = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label>{props.label}</label>
      <input
        onChange={(e) => props.onChange(e.target.value)}
        {...props}
      />
    </div>
  );
};