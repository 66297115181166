import { db } from "./init";
import { collection, query, getDocs, addDoc, doc, deleteDoc } from "firebase/firestore";

const collectionName = "companies";

export const getAll = async () => {
  var results = [];

  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();
    const company = {
      id: doc.id,
      address: data.address,
      corporate_form: data.corporate_form,
      email: data.email,
      name: data.name,
      organisation_number: data.organisation_number,
      phone_number: data.phone_number,
      postal_address: data.postal_address,
      postal_code: data.postal_code,
    };

    results.push(company);
  });

  return results;
};

export const addCompany = async (company) => {
  const docRef = await addDoc(collection(db, collectionName), company);
  console.log("Document written with ID: ", docRef.id);
  return docRef.id
};

export const deleteCompany = async (companyId) => {
  await deleteDoc(doc(db, collectionName, companyId));
}