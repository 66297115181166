import { useEffect, useState, createContext } from "react";
import { getCurrentUser, signIn, logOut } from "../firebase/auth";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [failedLogin, setFaliedLogin] = useState(false);

  const handleLogin = async () => {
    const result = await signIn()
    setUser(result);

    if (user === undefined) {
      setFaliedLogin(true)
    }
  };

  const handleLogout = () => {
    logOut();
    setUser(undefined);
    setFaliedLogin(false)
  };


  const value = {
    user,
    failedLogin,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  useEffect(() => {
    async function setUserAsync() {
      var currentUser = await getCurrentUser();
      setUser(currentUser);
      setFaliedLogin(false)
    }
    
    setUserAsync()
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
