import { db } from "./init";
import { collection, query, where, getDocs, addDoc, doc, deleteDoc } from "firebase/firestore";

const collectionName = "admins"

export const addAdmin = async (admin) => {
  const docRef = await addDoc(collection(db, collectionName), admin);
  console.log("Document written with ID: data.", docRef.id);
  return docRef.id
};

export const getAllByCompanyId = async (companyId) => {
  var results = [];

  const q = query(collection(db, collectionName), where("company_id", "==", String(companyId)));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();
    const admin = {
      id: doc.id,
      company_id: data.company_id,
      corporate_form: data.corporate_form,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone_number: data.phone_number
    };

    results.push(admin);
  });

  return results;
};

export const deleteByCompanyId = async (companyId) => {
  var admins = await getAllByCompanyId(companyId);

  for (const admin of admins) {
    await deleteDoc(doc(db, collectionName, admin.id));
  }
}