import {
  Container,
  TextsContainer,
  Title,
  Text,
  InputFieldsContainer,
  InputField,
} from "./Components.js";

const AddOfficeButton = ({ onClick }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label>Vill du lägga till ett kontor?</label>
      <button type="button" onClick={() => onClick()}>Lägg till kontor</button>
    </div>
  );
};

const OfficesPage = ({ createAccount, offices, setOffices }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    createAccount()
  };

  const handleChange = (e) => {
    const index = e.target.name;
    const value = e.target.value;
    setOffices((offices) => {
      var officesCopy = [...offices];
      officesCopy[index] = value;

      return officesCopy;
    });
  };

  const addOffice = () => {
    if (offices[offices.length - 1] !== "") {
      setOffices([...offices, ""])
    }
  }

  /*const handleOnChange = (newValue, index) => {
    setOffices((offices) => {
      var officesCopy = [...offices];
      officesCopy[index] = newValue;

      return officesCopy;
    });
  };*/

  return (
    <Container>
      <TextsContainer>
        <Title>Skulle du vilja lägga till ett kontor?</Title>
        <Text>Du kan alltid lägga till mer kontor senare</Text>
      </TextsContainer>
      <InputFieldsContainer onSubmit={handleSubmit}>
        {offices.map((officeName, index) => (
          <InputField
            type="text"
            key={`office-${index}`}
            placeholder="Adress"
            label={`Kontor ${index + 1}`}
            name={index}
            defaultValue={officeName}
            onChange={handleChange}
          />
        ))}
        <AddOfficeButton onClick={addOffice} />
        <button type="submit">Skapa Kundkonto</button>
      </InputFieldsContainer>
    </Container>
  );
};

export default OfficesPage;