import {
  signInWithPopup,
  GoogleAuthProvider,
  deleteUser,
  signOut,
} from "firebase/auth";
import { auth } from "./init";
import { findUserWithEmail } from "./users";

const provider = new GoogleAuthProvider();

export const getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      if (user) {
        resolve(user);
      }

      resolve(undefined);
    }, reject);
  });
};

export const logOut = async () => {
  await signOut(auth);
};

export const signIn = async () => {
  var response = await signInWithPopup(auth, provider);
  const user = response.user;
  const result = await findUserWithEmail(user.email);

  if (!result) {
    await deleteUser(user);
    await logOut();
  }

  return result;
};
