import { Outlet, useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Sidebar } from "../components/Sidebar";
import { SearchField } from "../components/SearchField";

const Container = styled.div`
  min-height: 100vh;

  display: flex;
  background-color: #efefef;
`;

const SidePage = styled.div`
  min-height: 100vh;
  flex-grow: 4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  gap: 50px;
`;

const SidePageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SidePageHeader = () => {
  return (
    <SidePageHeaderContainer>
      <SearchField />
    </SidePageHeaderContainer>
  );
};

const RootLayout = ({ indexPath }) => {
  const location = useLocation();
  const { onLogout } = React.useContext(AuthContext);

  if (location.pathname === "/") {
    return <Navigate to={`/${indexPath}`} replace={true} />;
  }

  return (
    <Container>
      {<Sidebar user={{ name: "Ali Diriye", email: "ali.diriye@gmail.com" }} onLogout={onLogout} />}
      <SidePage>
        <SidePageHeader />
        <Outlet />
      </SidePage>
    </Container>
  );
};

export { RootLayout };