import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getAll as getAllCompaines,
  deleteCompany,
} from "../firebase/companies";
import { deleteByCompanyId as deleteAdminsByCompanyId } from "../firebase/admins";
import { deleteByCompanyId as deleteOfficesByCompanyId } from "../firebase/offices";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: gray;
`;

const ClientsContainer = styled.div``;

const DeletationConfirmationContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeletationConfirmationDialog = styled.div`
  padding: 30px;
  background: white;
  border-radius: 10px;
`;

const Row = ({ label, value }) => {
  return (
    <p>
      <BoldText>{label}:</BoldText> {value}
    </p>
  );
};

const Clients = () => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [companyToDeleteId, setCompanyToDeleteId] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      const result = await getAllCompaines();
      setCompanies(result);
    };

    fetchCompanies();
  }, []);

  const handleDeletion = async (companyId) => {
    await deleteCompany(companyId);
    await deleteAdminsByCompanyId(companyId);
    await deleteOfficesByCompanyId(companyId);

    const companiesLeft = companies.filter(
      (company) => company.id != companyId
    );
    setCompanies(companiesLeft);
  };

  return (
    <Container>
      <Header>
        <button onClick={() => navigate("/add-client")}>Lägg till</button>
      </Header>
      <ClientsContainer>
        {companies.map((company) => {
          return (
            <div key={company.organisation_number}>
              <div
                style={{
                  border: "1px solid",
                  width: "fit-content",
                  padding: "10px",
                }}
              >
                <Row
                  label="Organisationsnummer"
                  value={company.organisation_number}
                />
                <Row label="Företagsnamn" value={company.name} />
                <Row label="Bolagsform" value={company.corporate_form} />
                <Row label="Telefonnummer" value={company.phone_number} />
                <Row label="Mejl" value={company.email} />
                <Row label="Adress" value={company.address} />
                <Row label="Postnummer" value={company.postal_code} />
                <Row label="Postort" value={company.postal_address} />
              </div>
              <button
                onClick={() => {
                  setCompanyToDeleteId(company.id);
                  setShowConfirmation(true);
                }}
              >
                Delete
              </button>
            </div>
          );
        })}
      </ClientsContainer>
      {showConfirmation && (
        <DeletationConfirmationContainer>
          <DeletationConfirmationDialog>
            <div>Är du säker på att du vill ta bort företaget?</div>
            <button
              onClick={() => {
                handleDeletion(companyToDeleteId);
                setShowConfirmation(false);
              }}
            >
              Delete
            </button>
            <button
              onClick={() => {
                setCompanyToDeleteId(null);
                setShowConfirmation(false);
              }}
            >
              Cancel
            </button>
          </DeletationConfirmationDialog>
        </DeletationConfirmationContainer>
      )}
    </Container>
  );
};

export { Clients };
