import { db } from "./init";
import { collection, query, where, getDocs, addDoc, doc, deleteDoc } from "firebase/firestore";

const collectionName = "offices"

export const addOffice = async (office) => {
  const docRef = await addDoc(collection(db, collectionName), office);
  console.log("Document written with ID: ", docRef.id);
  return docRef.id
};

export const addOffices = async (offices) => {
  for (const office of offices) {
    await addOffice(office)
  }
};

export const getAllByCompanyId = async (companyId) => {
  var results = [];

  const q = query(collection(db, collectionName), where("company_id", "==", String(companyId)));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();
    const office = {
      id: doc.id,
      address: data.address,
      company_id: data.company_id
    };

    results.push(office);
  });

  return results;
};

export const deleteByCompanyId = async (companyId) => {
  var offices = await getAllByCompanyId(companyId);

  for (const office of offices) {
    await deleteDoc(doc(db, collectionName, office.id));
  }
}