import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import React, { useEffect, useState } from 'react'

const ProtectedRoute = ({ children }) => {
  const { user } = React.useContext(AuthContext);
  
  if (user === undefined) {
    return <Navigate to="/login" replace={true} />;
  }

  return children;
};

export { ProtectedRoute }